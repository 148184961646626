import React, { useRef, useEffect } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import 'typeface-roboto';
import BottomBar from './BottomBar';
import NavigationBar from './NavigationBar';
import HomePage from './HomePage';
import PrivacyPolicy from './PrivacyPolicy';

const darkBlue = '#0E6890';//191F2D
const lightBlue = '#51c8f6';
const textColor = "#505050";
const theme = createMuiTheme({
  palette: {
    primary: {
      // main: "#cfe8f3",//darkBlue,
      main: "#56C8F2",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#191F2D",//lightBlue,
      contrastText: "#FFF",
    },
    text: {
      primary: textColor,
    }
  },
  typography: {
    // fontFamily: "'Open Sans', sans-serif",
    textTransform: "none",
    button: {
      textTransform: "none",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 90,
    }
  },
  overrides: {
    // MuiTableCell: {
    //   root: {
    //     paddingLeft: 24,
    //     paddingRight: 24,
    //   }
    // },
    // MuiInputBase: {
    //   root: {
    //     lineHeight: '1.25em',
    //   }
    // },
    // MuiTooltip: {
    //   tooltip: {
    //     fontSize: 16,
    //   }
    // }
  }
});
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appFrame: {
    minHeight: '100vh',
    zIndex: 1,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
  },
  content: {
    // marginTop: 0,
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    overflowY: "hidden",
    margin: 0,
  },
  toolbar: theme.mixins.toolbar,
});

function App(props) {
  const { classes } = props;
  const demoRef = useRef(null);
  const clientsRef = useRef(null);
  const contactUsRef = useRef(null);
  const testimonialRef = useRef(null);
  return (
    <div className={classes.root}>
        <div className={classes.appFrame}>
          <NavigationBar clientsRef={clientsRef} contactUsRef={contactUsRef} demoRef={demoRef} testimonialRef={testimonialRef} 
            history={props.history} location={props.location}/>
          <div className={classes.content}>
            <div className={classes.toolbar}/>
            <Switch>
              <Route exact path='/'
                render={(props) => <HomePage 
                  clientsRef={clientsRef}
                  contactUsRef={contactUsRef} 
                  demoRef={demoRef}
                  testimonialRef={testimonialRef}
                  {...props}
                  />}
              />
              <Route exact path='/privacy_policy'
                render={(props) => <PrivacyPolicy 
                  />}
              />
              <Route path="*"
                render={(props) => 
                  <Redirect to="/" />
                }
              />
            </Switch>
          </div>
          <BottomBar />
        </div>
    </div>
  );
}
const WrappedApp = withStyles(styles)(withRouter(App));

function AppWrapper(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <WrappedApp/>
    </MuiThemeProvider>
  )
}
export default AppWrapper
