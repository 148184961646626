
import React from 'react';
import { Grid, Typography, List, ListItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import BackgroundImage from './images/background.png';
import BackgroundImage2 from './images/background_2.png';
import BackgroundImage3 from './images/background_3.png';
import BackgroundImage4 from './images/background_4_2.png';
import MobileBackground from './images/mobile_background.png';
import ReportingImage from './images/instant_reporting.png';

 const slideImages = [
    BackgroundImage,
    BackgroundImage2,
    BackgroundImage3,
    BackgroundImage4,
];

const styles = theme => ({
    slideContainer: {
        // width: "80%",
        margin: "auto",
        backgroundColor: theme.palette.primary.main,//"#0E6890",
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    firstImage: {
        display: "flex",
        backgroundColor: "#bcdcea",
        // alignItems: "center",
        // justifyContent: "center",
        backgroundSize: "cover",
        backgroundPosition: "bottom left",
        width: "100%",
        minHeight: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)+10}px)`,
        borderBottom: "1px solid white"
    },
    imageBottom: {
        display: "flex",
        backgroundColor: "#bcdcea",
        // alignItems: "center",
        // justifyContent: "center",
        backgroundSize: "cover",
        backgroundPosition: "bottom left",
        width: "100%",
        minHeight: 600,//`calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`,
        borderBottom: "1px solid white"
    },
    imageGray: {
        backgroundColor: "#FAFAFA",
        display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        backgroundSize: "cover",
        backgroundPosition: "bottom left",
        width: "100%",
        minHeight: 600,//`calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`,
        borderBottom: "1px solid #56C8F3"
    },
    imageRight: {
        display: "block",
        maxWidth: 500,
        maxHeight: 300,
        width: "auto",
        height: "auto",
    },
    titleGrid: {
        paddingLeft:30, paddingRight:30, marginTop: 40, maxWidth: "660px",
        [theme.breakpoints.down('xs')]: {
            paddingLeft:15,paddingRight:15,
            // backgroundColor: theme.palette.secondary.main,
        },
    },
    titleGrid2: {
        paddingLeft:30, paddingRight:30, marginTop: 40, 
        [theme.breakpoints.down('xs')]: {
            paddingLeft:15,paddingRight:15,
            // backgroundColor: theme.palette.secondary.main,
        },
    },
    mobileView: {
        backgroundImage: `url(${MobileBackground})`,
        backgroundColor: "#56C9F3",
        backgroundSize: "cover",
        backgroundPosition: "bottom left",
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        height: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`,
    },
    subtext: {
        color: "#5B6B7F"
    },
    section: {
        paddingTop: 80, 
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
    },
    section2: {
        paddingTop: 80, 
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: "#56C9F3",
    },
    verticalInfo: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
});
function VerticalInfo(props){
    const { classes } = props;
    return (
        <div>
            <div className={classes.mobileView}>
                <Grid container direction='column' style={{height:"100%"}} justify="flex-start" alignContent="center">
                    {/* <Grid className={classes.titleGrid2} style={{height:"100%"}} item container justify="center"> */}
                        <Typography variant="h5" 
                                align="center"
                                style={{color: "white", fontWeight: 500, marginBottom: 30, marginTop: 20}}>
                            Modernize your StatRep
                        </Typography>
                        <Typography variant="h5" 
                                align="center"
                                style={{color: "white", fontWeight: 500, marginBottom: 30}}>
                            Simplified StatRep Reporting
                        </Typography>
                        <Typography variant="h5" 
                                align="center"
                                style={{color: "white", fontWeight: 500, marginBottom: 30}}>
                            Real-Time Situational Awareness              
                        </Typography>
                        <Typography variant="h5" 
                            align="center"
                            style={{color: "white", fontWeight: 500}}>
                        Secure, Cloud Native, Web Application
                    </Typography>
                    {/* </Grid> */}
                </Grid>
            </div>
            <div className={classes.verticalInfo}>
                <div className={classes.firstImage} style={{'backgroundImage': `url(${slideImages[0]})`}}>
                    <Grid  container direction='column'>
                        <Grid className={classes.titleGrid} item container direction="column">
                            <Typography variant="h4" //className={classes.titleText2} 
                            style={{marginBottom:50,marginTop:0, color: "white", fontWeight: 500}}
                            >Modernize your StatRep</Typography>
                            <Typography variant="h4" //className={classes.titleText2} 
                            style={{marginBottom:50,marginLeft:0, color: "white", fontWeight: 500}}
                            >Simplified StatRep Reporting</Typography>
                            <Typography variant="h4" //className={classes.titleText2} 
                            style={{marginBottom:50,marginLeft:0, color: "white", fontWeight: 500}}
                            >Real-Time Situational Awareness</Typography>
                            <Typography variant="h4" //className={classes.titleText2} 
                            style={{marginBottom:50,marginLeft:0, color: "white", fontWeight: 500}}
                            >Secure, Cloud Native, Web Application</Typography>
                        
                        {/*   <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>
                                Cloud Native. Secure. Web Based.
                            </Typography> */}
                        
                            {/* <Grid item>
                                <Button onClick={()=>window.scrollTo(0, contactUsRef.current.offsetTop)} 
                                    color="primary" variant="contained">
                                    Schedule Demo
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </div>
                {/* <Grid container className={classes.section} alignItems="center" justify="center">
                    <Grid item md={5}>
                        <Grid style={{height:"100%"}} container direction='column' justify='flex-start' alignItems='flex-start'>
                            <List>
                                <ListItem>
                                    <Typography className={classes.titleText} variant="h5">Instant Reporting in Real-Time</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography className={classes.subtext}>StatRep report submission based on hospitals and departments</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography className={classes.subtext}>Report submission summary and PDF download of reports</Typography>
                                </ListItem>
                                <ListItem className={classes.subtext}>
                                    <Typography>Real-time monitoring of report submission activity</Typography>
                                </ListItem>
                                <ListItem className={classes.subtext}>
                                    <Typography>All reports securely backed up and easily accessed</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item md={5} container direction='column' justify='center' alignItems="center">
                        <img className={classes.imageRight} src={ReportingImage}>
                        </img>
                    </Grid>
                </Grid>
                <Grid container className={classes.section2} alignItems="center" justify="center">
                    <Grid item md={5}>
                        <Grid style={{height:"100%"}} container direction='column' justify='flex-start' alignItems='flex-start'>
                            <List>
                                <ListItem>
                                    <Typography className={classes.titleText} variant="h5">Instant Reporting in Real-Time</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography className={classes.subtext}>StatRep report submission based on hospitals and departments</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography className={classes.subtext}>Report submission summary and PDF download of reports</Typography>
                                </ListItem>
                                <ListItem className={classes.subtext}>
                                    <Typography>Real-time monitoring of report submission activity</Typography>
                                </ListItem>
                                <ListItem className={classes.subtext}>
                                    <Typography>All reports securely backed up and easily accessed</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item md={5} container direction='column' justify='center' alignItems="center">
                        <img className={classes.imageRight} src={ReportingImage}>
                        </img>
                    </Grid>
                </Grid> */}
                <div className={classes.imageBottom} style={{'backgroundImage': `url(${slideImages[1]})`}}>
                    <Grid container direction='column'>
                        <Grid className={classes.titleGrid2} item container>
                            <Typography variant="h4" //className={classes.titleText2} 
                            align="center"
                            style={{marginBottom:20, marginTop:20,color: "white", fontWeight: 500}}
                            >Instant Reporting in Real-Time</Typography>
                            {/* <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>Provide your emergency management team the ability to achieve operational efficiency by streamlining workflows related to StatRep reporting.</Typography> */}
                        
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.imageBottom} style={{'backgroundImage': `url(${slideImages[2]})`}}>
                    <Grid container direction='column'>
                        <Grid className={classes.titleGrid2} item container>
                            <Typography variant="h4" //className={classes.titleText2} 
                            align="center"
                            style={{marginBottom:20, marginTop:20,color: "white", fontWeight: 500}}
                            >Monitor Reports. Make Decisions.</Typography>
                            {/* <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>Provide your emergency management team the ability to achieve operational efficiency by streamlining workflows related to StatRep reporting.</Typography> */}
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.imageGray} style={{'backgroundImage': `url(${slideImages[3]})`}}>
                    <Grid container direction='column'>
                        <Grid className={classes.titleGrid2} item container>
                            <Typography variant="h4" //className={classes.titleText2} 
                            align="center"
                            style={{marginBottom:20, marginTop:20,color: "#0D6891", fontWeight: 500}}
                            >Secure. Cloud Native. Web Application.</Typography>
                            {/* <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>Provide your emergency management team the ability to achieve operational efficiency by streamlining workflows related to StatRep reporting.</Typography> */}
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* <div className={classes.imageBottom} style={{backgroundColor: '#56C9F3'}}>
                <Grid container direction='column' justify="center" align="center">
                    <Grid className={classes.titleGrid2} item container justify="center" style={{paddingLeft: 100, paddingRight: 100, marginTop: 0}}>
                        <Typography variant="h5"
                            align="center"
                            style={{marginBottom:20, marginTop:50,color: "white", fontStyle: "italic"}}>
                            “No more paper. No more faxes. A simple online tool to submit a StatRep and a rich live dashboard for us to ensure situational awareness. StatRep by IntelHS saves us time and totally simplifies our emergency preparedness and response capabilities.”
                        </Typography>
                        <Typography variant="h5" align="center" style={{marginBottom:20,color: "black", fontWeight: 500}}>
                            - Jared Shapiro, DrPHc, Senior Systems Director<br/>
                            Environmental Health and Safety, Montefiore Health System
                        </Typography>
                    </Grid>
                </Grid>
            </div> */}
        </div>
    )
}
export default withStyles(styles)(VerticalInfo);