import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from 'react-ga';
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom'
const trackingId = "UA-154487447-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
// ReactGA.set({
//   userId: auth.currentUserId(),
// })

ReactDOM.render(<Router><App/></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
