import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button, TextField, CircularProgress, Snackbar } from '@material-ui/core'
import { useFormInput } from './CustomHooks';
import axios from 'axios';
import MySnackbarContentWrapper from './SnackbarContentWrapper'

const styles = theme => ({
    titleText:{
        // fontFamily: "Montserrat",
        fontWeight: 500,
        paddingBottom: 5,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        // borderColor: theme.palette.secondary.main,
        borderColor: "#56C8F3",//theme.palette.primary.main,
        borderStyle: "solid",
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
        marginBottom: 20,
    },
    textField: {
        backgroundColor: "white"
    },
    section: {
        paddingTop: 80, 
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
        // backgroundColor: "#79D3F7",
        // minHeight: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`
    },
    sectionGray: {
        paddingTop: 80, 
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: "#ECEEF1"
        // backgroundColor: "#56C8F2"
    },
});
function ContactUs(props) {
    const { classes } = props;
    const nameInput = useFormInput('');
    const emailInput = useFormInput('');
    const organizationInput = useFormInput('');
    const messageInput = useFormInput('');
    const phoneInput = useFormInput('');
    const [contactUsLoading, setContactUsLoading] = useState(false);
    const [contactUsSuccess, setContactUsSuccess] = useState(false);
    const [contactUsFailure, setContactUsFailure] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [organizationError, setOrganizationError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    
    const handleContactUsSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setContactUsSuccess(false);
    };
    const handleContactUsFailureClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setContactUsFailure(false);
    };
    function validateContactUsFields(){
        var valid = true;
        if(nameInput.value===""){
            setNameError(true);
            valid = false;
        }
        else{
            setNameError(false);
        }
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(emailInput.value)){
            setEmailError(true);
            valid = false;
        }
        else{
            setEmailError(false);
        }
        if(phoneInput.value===""){
            setPhoneError(true);
            valid = false;
        }
        else{
            setPhoneError(false);
        }
        if(organizationInput.value===""){
            setOrganizationError(true);
            valid = false;
        }
        else{
            setOrganizationError(false);
        }
        if(messageInput.value===""){
            setMessageError(true);
            valid = false;
        }
        else{
            setMessageError(false);
        }
        return valid;
    }
    function sendMail(){
        if(validateContactUsFields()){
            setContactUsLoading(true);
            axios.post(process.env.REACT_APP_API_URL+'/sendContactUsEmail',{
                name: nameInput.value,
                email: emailInput.value,
                phone: phoneInput.value,
                organization: organizationInput.value,
                message: messageInput.value
            })
            .then(response => {
                setContactUsLoading(false);
                if(response.data==="success"){
                    nameInput.onChange({target:{value:''}})
                    emailInput.onChange({target:{value:''}})
                    phoneInput.onChange({target:{value:''}})
                    organizationInput.onChange({target:{value:''}})
                    messageInput.onChange({target:{value:''}})
                    setContactUsSuccess(true);
                }
                else{
                    setContactUsFailure(true);
                }
            })
            .catch(error => {
                setContactUsFailure(true);
                setContactUsLoading(false);
            })
        }
    }
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={contactUsSuccess}
                autoHideDuration={5000}
                onClose={handleContactUsSuccessClose}
                >
                <MySnackbarContentWrapper
                    onClose={handleContactUsSuccessClose}
                    variant="success"
                    message="Email sent successfully!"
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={contactUsFailure}
                autoHideDuration={5000}
                onClose={handleContactUsFailureClose}
                >
                <MySnackbarContentWrapper
                    onClose={handleContactUsFailureClose}
                    variant="error"
                    message="There was a problem sending the email."
                />
            </Snackbar>
            <Grid container className={classes.section} ref={props.contactUsRef}>
                <Grid container justify="center">
                    <Typography className={classes.titleText} variant="h4" align="center">Contact Us to Schedule a Demo</Typography>
                </Grid>
                {/* <Grid container justify="center">
                    <Grid item xs={3}><hr className={classes.primaryColor}></hr></Grid>
                </Grid> */}
                <Grid item xs={12} style={{height:10}}/>
                <Grid container direction="column" alignItems="center">
                    <Grid container style={{maxWidth:"500px"}}>
                        <TextField className={classes.textField}
                            error={nameError} 
                            {...nameInput} variant="outlined" placeholder="Full Name" fullWidth style={{marginBottom:20}}
                        />
                        <TextField className={classes.textField} type="email" error={emailError} {...emailInput} variant="outlined" placeholder="Email Address" fullWidth style={{marginBottom:20}}/>
                        <TextField className={classes.textField} type="tel" error={phoneError} {...phoneInput} variant="outlined" placeholder="Phone" fullWidth style={{marginBottom:20}}/>
                        <TextField className={classes.textField} error={organizationError} {...organizationInput} variant="outlined" placeholder="Organization" fullWidth style={{marginBottom:20}}/>
                        <TextField className={classes.textField} 
                            error={messageError} 
                            {...messageInput}
                            placeholder="Message"
                            multiline 
                            variant="outlined"
                            rows={5}
                            fullWidth
                            style={{marginBottom:20}}
                        />
                        {contactUsLoading?
                            <Grid container item justify="center" alignItems="center"><CircularProgress/></Grid>:
                            <Button variant="contained" color="primary" fullWidth onClick={()=>sendMail()}>Submit</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
export default withStyles(styles)(ContactUs);