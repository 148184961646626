import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Link, IconButton, Grid } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    bottomBar:{
        backgroundColor: theme.palette.secondary.main,
    },
    textGrid: {
        paddingLeft: 50,
        paddingTop: 30,
        marginBottom: 20,
    },
    title: {
        fontSize: 18,
        color: "#0E6890",
    },
    horizLine: {
        border: "none",
        height: 1,
        backgroundColor: "#FFF"
    },
    copyrightText: {
        padding: 20,
        paddingLeft: 40,
        color: theme.palette.secondary.contrastText,
    },
    contrastText: {
        color: theme.palette.secondary.contrastText,
    },
});

function BottomBar(props) {
    const { classes } = props;
    return (
        <div className={classes.bottomBar}>
            <Grid container className={classes.textGrid}>
                <Grid item md={3}>
                    <Typography className={classes.title} color="secondary" variant="body1">
                        ABOUT INTELHS
                    </Typography>
                    <Typography className={classes.contrastText}>
                        Intelligent Health System (IntelHS) provides hospital administration teams the ability to achieve operational excellence by streamlining workflows.
                    </Typography>
                </Grid>
                <Grid item md={1}/>
                <Grid item md={3}>
                    <Typography className={classes.title} color="secondary" variant="body1">
                        GET IN TOUCH
                    </Typography>
                    <Typography className={classes.contrastText}>
                        SEQ Technology LLC
                    </Typography>
                    <Typography className={classes.contrastText}>
                        1460 Broadway
                    </Typography>
                    <Typography className={classes.contrastText}>
                        New York, NY 10036
                    </Typography>
                    <Typography className={classes.contrastText}>
                        Email: questions@intelhs.com
                    </Typography>
                    <Typography className={classes.contrastText}>
                        Phone: (646) 225-4039
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography className={classes.title} color="secondary" variant="body1">
                        USEFUL LINKS
                    </Typography>
                    {/* <Typography className={classes.whiteText}>
                        <IconButton
                            fontSize="15"
                            href={"https://www.linkedin.com/company/seq-technology-llc/"}
                        >
                            <LinkedInIcon style={{color:"white"}} />
                        </IconButton>    
                    </Typography> */}
                    <Typography className={classes.contrastText}>
                        <Link component={RouterLink} 
                            color="inherit"
                            to="/privacy_policy"
                            onClick={()=>window.scrollTo(0, 0)}
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                    {/* <Typography className={classes.whiteText}>
                        <Link component={RouterLink} 
                            color="inherit"
                            to="/termsandconditions"
                        >
                            Terms and Conditions
                        </Link>
                    </Typography> */}
                </Grid>
            </Grid>
            <hr className={classes.horizLine}/>
            <Typography className={classes.copyrightText} color="secondary" variant="caption">
                Copyright 2020 SEQ Technology LLC. All Rights Reserved
            </Typography>
        </div>
    );
}
export default withStyles(styles)(BottomBar);