
import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Slide } from 'react-slideshow-image';
import {Breakpoint } from 'react-socks';

import BackgroundImage from './images/background.png';
import BackgroundImage2 from './images/background_2.png';
import BackgroundImage3 from './images/background_3.png';
import BackgroundImage4 from './images/background_4.png';
import MobileBackground from './images/mobile_background.png';

 const slideImages = [
    BackgroundImage,
    BackgroundImage2,
    BackgroundImage3,
    BackgroundImage4,
];


const bottomBarHeight = 50;
const styles = theme => ({
    slideContainer: {
        // width: "80%",
        margin: "auto",
        backgroundColor: theme.palette.primary.main,//"#0E6890",
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    imageBottom: {
        display: "flex",
        backgroundColor: "#bcdcea",
        // alignItems: "center",
        // justifyContent: "center",
        backgroundSize: "cover",
        backgroundPosition: "bottom left",
        width: "100%",
        minHeight: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight+39+bottomBarHeight)}px)`,
    },
    image: {
        backgroundColor: "#bcdcea",
        display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        backgroundSize: "cover",
        width: "100%",
        minHeight: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight+39+bottomBarHeight)}px)`,
    },
    titleGrid: {
        paddingLeft:30, paddingRight:30, marginTop: 40, maxWidth: "660px",
        [theme.breakpoints.down('xs')]: {
            paddingLeft:15,paddingRight:15,
            // backgroundColor: theme.palette.secondary.main,
        },
    },
    titleGrid2: {
        paddingLeft:30, paddingRight:30, marginTop: 40, 
        [theme.breakpoints.down('xs')]: {
            paddingLeft:15,paddingRight:15,
            // backgroundColor: theme.palette.secondary.main,
        },
    },
    mobileView: {
        backgroundImage: `url(${MobileBackground})`,
        backgroundColor: "#56C9F3",
        backgroundSize: "cover",
        backgroundPosition: "bottom left",
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        height: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`,
    },
});
  
function Slideshow(props){
    const { classes, contactUsRef } = props;
    return (
        <div>
            <div className={classes.mobileView}>
                <Grid container direction='column' style={{height:"100%"}} justify="flex-start" alignContent="center">
                    {/* <Grid className={classes.titleGrid2} style={{height:"100%"}} item container justify="center"> */}
                        <Typography variant="h5" 
                                align="center"
                                style={{color: "white", fontWeight: 500, marginBottom: 30, marginTop: 20}}>
                            Modernize your StatRep
                        </Typography>
                        <Typography variant="h5" 
                                align="center"
                                style={{color: "white", fontWeight: 500, marginBottom: 30}}>
                            Instant Reporting in Real-Time
                        </Typography>
                        <Typography variant="h5" 
                                align="center"
                                style={{color: "white", fontWeight: 500, marginBottom: 30}}>
                            Monitor Reports. Make Decisions.                            
                        </Typography>
                        <Typography variant="h5" 
                            align="center"
                            style={{color: "white", fontWeight: 500}}>
                        Secure. Cloud Native. Web Application.
                    </Typography>
                    {/* </Grid> */}
                </Grid>
            </div>
        
            <div className={classes.slideContainer}>
                <Slide 
                    duration={7000}
                    transitionDuration={800}
                    infinite={true}
                    indicators={true}
                    arrows={true}
                    onChange={(oldIndex, newIndex) => {
                        // console.log(`slide transition from ${oldIndex} to ${newIndex}`);
                    }}>
                    <div>
                        <div className={classes.imageBottom} style={{'backgroundImage': `url(${slideImages[0]})`}}>
                            <Grid  container direction='column'>
                                <Grid className={classes.titleGrid} item container>
                                    <Typography variant="h4" //className={classes.titleText2} 
                                    align="center"
                                    style={{marginBottom:20,marginTop:-30, color: "white", fontWeight: 500}}
                                    >Modernize your StatRep</Typography>
                                
                                {/*   <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>
                                        Cloud Native. Secure. Web Based.
                                    </Typography> */}
                                
                                    {/* <Grid item>
                                        <Button onClick={()=>window.scrollTo(0, contactUsRef.current.offsetTop)} 
                                            color="primary" variant="contained">
                                            Schedule Demo
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div>
                        <div className={classes.imageBottom} style={{'backgroundImage': `url(${slideImages[1]})`}}>
                            <Grid container direction='column'>
                                <Grid className={classes.titleGrid2} item container>
                                    <Typography variant="h4" //className={classes.titleText2} 
                                    align="center"
                                    style={{marginBottom:20, marginTop:-30,color: "white", fontWeight: 500}}
                                    >Instant Reporting in Real-Time</Typography>
                                    {/* <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>Provide your emergency management team the ability to achieve operational efficiency by streamlining workflows related to StatRep reporting.</Typography> */}
                                
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div>
                        <div className={classes.imageBottom} style={{'backgroundImage': `url(${slideImages[2]})`}}>
                            <Grid container direction='column'>
                                <Grid className={classes.titleGrid2} item container>
                                    <Typography variant="h4" //className={classes.titleText2} 
                                    align="center"
                                    style={{marginBottom:20, marginTop:-30,color: "white", fontWeight: 500}}
                                    >Monitor Reports. Make Decisions.</Typography>
                                    {/* <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>Provide your emergency management team the ability to achieve operational efficiency by streamlining workflows related to StatRep reporting.</Typography> */}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div>
                        <div className={classes.imageBottom} style={{'backgroundImage': `url(${slideImages[3]})`}}>
                            <Grid container direction='column'>
                                <Grid className={classes.titleGrid2} item container>
                                    <Typography variant="h4" //className={classes.titleText2} 
                                    align="center"
                                    style={{marginBottom:20, marginTop:-30,color: "white", fontWeight: 500}}
                                    >Secure. Cloud Native. Web Application.</Typography>
                                    {/* <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>Provide your emergency management team the ability to achieve operational efficiency by streamlining workflows related to StatRep reporting.</Typography> */}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div>
                    <div className={classes.imageBottom} style={{backgroundColor: '#56C9F3'}}>
                        <Grid container direction='column' justify="center" align="center">
                            <Grid className={classes.titleGrid2} item container justify="center" style={{paddingLeft: 100, paddingRight: 100, marginTop: 0}}>
                                {/* <Typography variant="h5" //className={classes.titleText2} 
                                align="center"
                                style={{marginBottom:20, color: "white", fontStyle: "italic"}}
                                >“The StatRep Application from IntelHS greatly simplifies and streamlines our emergency management workflow before, during and post incident. The tool allows us to gain just in time situational awareness/information from the entire organization with a single dashboard. It's easy to use and saves us a lot of time.”
                                </Typography>
                                <Typography variant="h5" align="center" style={{marginBottom:20, color: "black", fontWeight: 500}}>
                                    - David Blacksberg, Supervisor, UC San Diego Health
                                </Typography> */}
                                <Typography variant="h5"
                                    align="center"
                                    style={{marginBottom:20, marginTop:50,color: "white", fontStyle: "italic"}}>
                                    “No more paper. No more faxes. A simple online tool to submit a StatRep and a rich live dashboard for us to ensure situational awareness. StatRep by IntelHS saves us time and totally simplifies our emergency preparedness and response capabilities.”
                                </Typography>
                                <Typography variant="h5" align="center" style={{marginBottom:20,color: "black", fontWeight: 500}}>
                                    - Jared Shapiro, DrPHc, Senior Systems Director<br/>
                                    Environmental Health and Safety, Montefiore Health System
                                </Typography>
                                {/* <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>Provide your emergency management team the ability to achieve operational efficiency by streamlining workflows related to StatRep reporting.</Typography> */}
                            </Grid>
                        </Grid>
                    </div>
                    </div>
                </Slide>
                <div style={{height:bottomBarHeight}}/>
            </div>
        </div>
    )
}
export default withStyles(styles)(Slideshow);
