import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, IconButton, Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom'
import logo from './images/logo.png';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        // paddingLeft: 24,
        // paddingTop: 20,
        width: 50,
        height: "auto",
    },
    button: {
        color: theme.palette.primary.contrastText,
        // '&:hover': {
        //     color: theme.palette.secondary.main,
        // },       
    },
    buttonGroup: {
        marginRight: 0,
    },
    primary: {},
    icon: {},
    menu: {
        zIndex: 4,
    },
    title: {
        fontWeight: 500, 
        fontSize: 50,
        [theme.breakpoints.down('lg')]: {
            fontSize: 45
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 35
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 25
        },
    },
    fixedBar: {
        backgroundColor: "#191F2D",
    }
});

function NavigationBar(props) {
    const { classes, clientsRef, contactUsRef, demoRef, testimonialRef } = props;
    const [isTop, setIsTop] = useState(true);
    useEffect(() => {
      document.addEventListener("scroll", () => {
        const scrollCheck = window.scrollY < 10
        if (scrollCheck !== isTop) {
            setIsTop(scrollCheck)
        }
      })
    })
    return (
        <div className={classes.root}>
            <AppBar className={isTop?undefined:classes.fixedBar} position="fixed" elevation={0} >
                <Toolbar>
                    <Grid container>
                        <Grid component={Box} item sm={8} xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={2} md={1}>
                                    <Button style={{marginLeft: -10, paddingLeft: 0}}>
                                    <img className={classes.logo}
                                        src={logo}
                                        alt=''
                                        onClick={()=>{
                                            props.history.push('/');
                                            window.scrollTo(0, 0);
                                        }}
                                    />
                                    </Button>
                                </Grid>
                                <Grid item xs={10} md={11}>
                                    <Typography className={classes.title} style={isTop?{color: "black"}:undefined} align="left" variant="h4">IntelHS - The StatRep Application</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item sm={8} xs={10} container alignItems='center' justify="center">
                            <Typography className={classes.title} align="center" variant="h4">IntelHS - The StatRep Application</Typography>
                        </Grid> */}
                        {/* <Grid component={Box} item sm={1} display={{ xs: 'block', sm: 'none'}}></Grid> */}
                        <Grid component={Box} item sm={4} className={classes.buttonGroup} display={{ xs: 'none', sm: 'block'}}>
                            {/* <Button onClick={()=>window.scrollTo(0, clientsRef.current.offsetTop)} color="secondary" size="large" className={classes.button}>
                                Clients
                            </Button> */}
                            {props.location.pathname==='/'?
                            <Grid container style={{height:"100%"}} alignItems='center' justify='flex-end' >
                                <Button onClick={()=>
                                    window.scrollTo(0, demoRef.current.offsetTop-20)
                                    } color="primary" size="large" className={classes.button}>
                                    Watch Video
                                </Button>
                                <Button onClick={()=>
                                    window.scrollTo(0, testimonialRef.current.offsetTop-20)
                                    } color="primary" size="large" className={classes.button}>
                                    Testimonials
                                </Button>
                                <Button onClick={()=>
                                    window.scrollTo(0, contactUsRef.current.offsetTop-20)
                                    } color="primary" size="large" className={classes.button}>
                                    Schedule Demo
                                </Button>
                            </Grid>:undefined}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}

NavigationBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NavigationBar);