import React from 'react';

export default function PrivacyPolicy(props){
    return (
        <div style={{padding: 30}}>
            <h1>IntelHS Privacy Policy</h1>
            <p>This Privacy Policy covers the information we collect about you when you use our products or services, or otherwise interact with us. IntelHS, we and us refers to SEQ Technology LLC, the creator of the software product IntelHS.</p>
            <p>This Privacy Policy sets out how IntelHS uses and protects any information that you give IntelHS when you use this website. We are committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, you can be assured that it will only be used in accordance with this Privacy Policy. We may change this policy from time to time by updating this page, with or without notice to you. It is your obligation to check this page from time to time. Your continued use of IntelHS constitutes acceptance of any changes to this Privacy Policy.
            </p>
            <h2>What we collect</h2>
            <p>We may collect the following information:</p>
            <ul>
               <li> Name</li>
               <li> Email Address</li>
               <li> Your IP Address</li>
               <li> Product identifier for version and make of browser (e.g. Internet Explorer 4.5)</li>
             </ul>
             <h2>What we do with the information we gather</h2>
             <ul>
               <li> Internal record keeping.</li>
               <li> We may use the information to improve our products and services.</li>
             </ul>
             This information is never shared with other parties.
             <h2>Security</h2>
             <p>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place appropriate physical, electronic and managerial procedures to safeguard and secure the information we collect online in accordance with industry standards.
             </p>
             <h2>Your Consent</h2>
             <p>By using our site or apps, you consent to our privacy policy.</p>
             <h2>Contacting Us</h2>
             <p>If you have questions regarding this privacy policy, you may email us at questions@intelhs.com.</p>
            Last Updated: January 17, 2020 
        </div> 
    )
}