import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, ListItem, List, Button, TextField, CircularProgress, Snackbar } from '@material-ui/core'
import { Player, BigPlayButton } from 'video-react';
// import BackgroundImage from './images/iStock-695360068.jpg';
import BackgroundImage from './images/background.png';
import BackgroundImage2 from './images/background_2.png';
import BackgroundImage3 from './images/background_3.png';
import BackgroundImage4 from './images/background_4.png';
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css"
import { Waypoint } from 'react-waypoint';
import CheckIcon from '@material-ui/icons/Check';
// import CloudImage from './images/cloud.png';
// import DashboardImage from './images/dashboard_management.png';
// import DashboardCharts from './images/dashboard_charts.png';
// import DashboardSubmission from './images/dashboard_submission.png';
// import DashboardStaffing from './images/dashboard_staffing.png';
// import DashboardStatuses from './images/dashboard_statuses.png';
// import DashboardImpactProblems from './images/dashboard_impactproblems.png';
// import StatRepImage from './images/statrep_1.png';
// import ReportsImage from './images/reports_page.png';
// import ReportUpdateImage from './images/report_update.png';
// import Video2 from './videos/Media2.mp4';
// import Video3 from './videos/Media3.mp4';
// import UCSDLogo from './images/ucsd_logo.jpg';
// import MontefioreLogo from './images/Monte_logo.png';
import { useFormInput } from './CustomHooks';
import 'video-react/dist/video-react.css';
import withTracker from './withTracker';
import { borders } from '@material-ui/system';
import Slideshow from './Slideshow';
// import "./App.css"
import ContactUs from './ContactUs';
import Demo from './Demo';
import VerticalInfo from './VerticalInfo';

const styles = theme => ({
    text: {
        // fontSize: 26,
        // fontWeight: 500,
        // color: 'black',
    },
    page: {
        // paddingTop: 30,
        // paddingBottom: 30,
        // paddingLeft: 50,
        // paddingRight: 50,
        // minHeight: 250,
    },
    backgroundImage: {
        height: "80vh",
        width: "100%",
    },
    background: {
        backgroundSize: "cover",
        backgroundImage: `url(${BackgroundImage})`,
        height: `100vh`,
        width: "100%",
        backgroundPosition: "center center",
    },
    background2: {
        backgroundSize: "cover",
        backgroundImage: `url(${BackgroundImage})`,
        height: `100vh`,
        width: "100%",
    },
    background3: {
        backgroundSize: "cover",
        backgroundImage: `url(${BackgroundImage2})`,
        minHeight: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`,
        width: "100%",
    },
    background4: {
        backgroundSize: "cover",
        backgroundImage: `url(${BackgroundImage3})`,
        minHeight: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`,
        width: "100%",
    },
    background5: {
        backgroundSize: "cover",
        backgroundImage: `url(${BackgroundImage4})`,
        minHeight: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`,
        width: "100%",
    },
    carouselImage: {
        // width: "auto",
        // height: "400px",
        // resize: {
        //     width: "auto",
        //     height: "400px"
        // }
    },
    titleText:{
        // fontFamily: "Montserrat",
        fontWeight: 500,
        paddingBottom: 5,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        // borderColor: theme.palette.secondary.main,
        borderColor: "#56C8F3",//theme.palette.primary.main,
        borderStyle: "solid",
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
        marginBottom: 20,
    },
    titleText2:{
        fontFamily: "Montserrat",
        fontWeight: "bold",
        textShadow: "2px 2px 3px rgba(255,255,255,0.1)",
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
    },
    titleGrid: {
        paddingLeft:30, paddingRight:30, marginTop: 40, maxWidth: "660px",
        [theme.breakpoints.down('xs')]: {
            paddingLeft:15,paddingRight:15,
            // backgroundColor: theme.palette.secondary.main,
        },
    },
    titleGrid2: {
        paddingLeft:30, paddingRight:30, marginTop: 40, 
        [theme.breakpoints.down('xs')]: {
            paddingLeft:15,paddingRight:15,
            // backgroundColor: theme.palette.secondary.main,
        },
    },
    subtitle:{
        textShadow: "2px 2px 3px rgba(255,255,255,0.1)"
    },
    section: {
        paddingTop: 80, 
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
        borderBottom: "1px solid #56C8F3"
    },
    sectionGray: {
        paddingTop: 80, 
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: "#ECEEF1"
        // backgroundColor: "#56C8F2"
    },
    primaryColor: {
        borderColor: theme.palette.secondary.main,
        backgroundColor:  theme.palette.secondary.main,
        color: theme.palette.secondary.main,
    },
    textField: {
        backgroundColor: "white"
    },
    quoteGrid: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 100,
            paddingRight: 100,
        }
    },
    toolbar: theme.mixins.toolbar,
});
function HomePage(props) {
    const { classes, demoRef } = props;
    // const demoRef = useRef(null);
    // const video1Ref = useRef(null);
    // const video2Ref = useRef(null);
    // const video3Ref = useRef(null);
    // const [video1Entered, setVideo1Entered] = useState(false);
    // const [video2Entered, setVideo2Entered] = useState(false);
    // const [video3Entered, setVideo3Entered] = useState(false);
    // const galleryDashboardRef = useRef(null);
    // const galleryStatRepRef = useRef(null);
    // const [galleryEntered, setGalleryEntered] = useState(false);
    // const [galleryDashboardEntered, setGalleryDashboardEntered] = useState(false);

    useEffect(()=>{
        window.addEventListener('scroll',onScroll,false)
    },[]);
    function onScroll(){
        // console.log("ABCDEFG")
    }
    // const dashboardImages = [
    //     {original: DashboardCharts,},
    //     {original: DashboardSubmission,},
    //     {original: DashboardStaffing,},
    //     {original: DashboardStatuses,},
    //     {original: DashboardImpactProblems,},
    // ];
    // const statRepImages = [
    //     {original: StatRepImage,},
    //     {original: ReportsImage,},
    //     {original: ReportUpdateImage,},
    // ];
    return (
        <div>
            {/* <Slideshow
                contactUsRef={props.contactUsRef}
            /> */}
            <VerticalInfo/>
            <Demo
                demoRef={props.demoRef}
            />
            <Grid ref={props.testimonialRef} container className={classes.section}>
                <Grid container justify="center">
                    <Typography className={classes.titleText} variant="h4" align="center">Testimonials</Typography>
                </Grid>
                <Grid className={classes.quoteGrid} item container justify="center">
                    {/* <Typography variant="h5" //className={classes.titleText2} 
                    align="center"
                    style={{marginBottom:20, marginTop: 10,color: "#1F668F",  fontStyle: "italic"}}
                    >“The StatRep Application from IntelHS greatly simplifies and streamlines our emergency management workflow before, during and post incident. The tool allows us to gain just in time situational awareness/information from the entire organization with a single dashboard. It's easy to use and saves us a lot of time.”
                    </Typography>
                    <Typography variant="h6" align="center" style={{marginBottom:20,color: "#1F668F", fontWeight: 500, fontSize: 25}}>
                        - David Blacksberg, Supervisor, UC San Diego Health
                    </Typography> */}
                    <Typography variant="h5"
                        align="center"
                        style={{marginBottom:20, marginTop:20,color: "#1F668F", fontStyle: "italic"}}>
                        “No more paper. No more faxes. A simple online tool to submit a StatRep and a rich live dashboard for us to ensure situational awareness. StatRep by IntelHS saves us time and totally simplifies our emergency preparedness and response capabilities.”
                    </Typography>
                    <Typography variant="h6" align="center" style={{marginBottom:20,color: "#1F668F", fontWeight: 500, fontSize: 25}}>
                        - Jared Shapiro, DrPHc, Senior Systems Director<br/>
                        Environmental Health and Safety, Montefiore Health System
                    </Typography>
                    {/* <Typography variant="h5" style={{marginBottom:50, color: "#0E6890", fontWeight: 500}}>Provide your emergency management team the ability to achieve operational efficiency by streamlining workflows related to StatRep reporting.</Typography> */}
                </Grid>
            </Grid>
            <ContactUs
                contactUsRef={props.contactUsRef} 
            />
        </div>
    );
}

export default withTracker(withStyles(styles)(HomePage));
