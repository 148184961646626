import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, ListItem, List, Button, TextField, CircularProgress, Snackbar } from '@material-ui/core'
import { Player, BigPlayButton } from 'video-react';
import { Waypoint } from 'react-waypoint';
import Video from './videos/demo_v2.mp4';
import 'video-react/dist/video-react.css';

const styles = theme => ({
    titleText:{
        // fontFamily: "Montserrat",
        fontWeight: 500,
        paddingBottom: 5,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        // borderColor: theme.palette.secondary.main,
        borderColor: "#56C8F3",//theme.palette.primary.main,
        borderStyle: "solid",
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
        marginBottom: 20,
    },
    section: {
        paddingTop: 80, 
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
        borderBottom: "1px solid #56C8F3"
        // backgroundColor: "#79D3F7",
        // minHeight: `calc(100vh - ${parseInt(theme.mixins.toolbar.minHeight)}px)`
    },
    sectionGray: {
        paddingTop: 80, 
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: "#ECEEF1"
        // backgroundColor: "#56C8F2"
    },
    primaryColor: {
        borderColor: theme.palette.secondary.main,
        backgroundColor:  theme.palette.secondary.main,
        color: theme.palette.secondary.main,
    },
    textField: {
        backgroundColor: "white"
    },
    toolbar: theme.mixins.toolbar,
});
function Demo(props) {
    const { classes, demoRef } = props;
    const video1Ref = useRef(null);
    const [video1Entered, setVideo1Entered] = useState(false);

    return (
        <div>
            <Grid ref={demoRef} container className={classes.section}>
                <Grid container justify="center">
                    <Typography className={classes.titleText} variant="h4" align="center">Watch Video</Typography>
                </Grid>
                {/* <Grid container justify="center">
                    <Grid item xs={3}><hr className={classes.primaryColor}></hr></Grid>
                </Grid> */}
                <Grid container justify="center">
                    <Grid item md={9} xs={12} style={{paddingTop: 5}}>
                        <Grid style={{border: "1px solid #000"}} container direction='column' justify='center' alignItems="center">
                            <Player ref={video1Ref}>
                                <BigPlayButton position="center" />
                                <source src={Video} type="video/mp4"></source>
                            </Player>
                            <Waypoint onEnter={()=>{
                                if(!video1Entered){
                                    setVideo1Entered(true);
                                    video1Ref.current.play()
                                }}}
                                onLeave={()=>{}}>
                                <div></div>
                            </Waypoint>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(Demo);

